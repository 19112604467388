import React, { lazy, Suspense, useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Container, CircularProgress, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { UserContext, UserProvider } from './context/UserContext';
import BottomNav from './components/BottomNav';
import QRCode from 'qrcode.react';
import background from './assets/farm-background.png'; 
import './i18n';

const MainPage = lazy(() => import('./pages/MainPage.jsx'));
const ReferralsPage = lazy(() => import('./pages/ReferralsPage.jsx'));
const LevelUpBoost = lazy(() => import('./pages/LevelUpBoost.jsx'));
const SettingsPage = lazy(() => import('./pages/SettingsPage.jsx')); 
const TaskPage = lazy(() => import('./pages/TaskPage.jsx')); 
const TopUsersPage = lazy(() => import('./pages/TopUsersPage.jsx'));
const ReferralMarketplacePage = lazy(() => import('./pages/ReferralMarketplacePage.jsx'));
const DailyComboDetail = lazy(() => import('./pages/DailyComboDetail.jsx'));
const JumpingPig = lazy(() => import('./components/PigGame.jsx'));
//const BadgeCollectionPage = lazy(() => import('./pages/BadgeCollectionPage'));
const MapGrid = lazy(() => import('./components/MapGrid.jsx'));

function App() {
  const { setUser, setUserBalances, setUserReferralEarnings, setConfig, setInitData, setIsMobile } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.Telegram.WebApp.ready();
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.enableClosingConfirmation();
    }

    const initData = window.Telegram.WebApp.initData;
    if (!initData) {
      setError(
        <Container maxWidth="sm">
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography variant="h4" gutterBottom>
              {t('desktopAccessNotAllowed')}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('pleaseUseMobileDevice')}
            </Typography>
            <QRCode value="https://t.me/FermaSosediBot/ferma" size={256} />
            <Typography variant="body1" sx={{ mt: 2 }}>
              {t('orUseLinkBelow')}
            </Typography>
            <a href="https://t.me/FermaSosediBot/ferma" target="_blank" rel="noopener noreferrer">
              https://t.me/FermaSosediBot/ferma
            </a>
          </Box>
        </Container>
      );
      setIsLoading(false);
      return;
    }

    setInitData(initData);

    const fetchData = async () => {
      try {
        const response = await axios.post('/register', { initData }, { timeout: 5000 });
        setUser(response.data.user);
        setUserBalances(response.data.userBalances);
        setUserReferralEarnings(response.data.userReferralEarnings);
        setIsMobile(/android|ios/i.test(window.Telegram.WebApp.platform || ''));
      } catch (error) {
        setError(error.response?.data?.error || t('serverError'));
      }

      try {
        const configResponse = await axios.get('/config', { timeout: 5000 });
        setConfig(configResponse.data);
      } catch (error) {
        setError(t('configLoadingError'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [t, setUser, setUserBalances, setUserReferralEarnings, setConfig, setInitData, i18n, setIsMobile]);

  useEffect(() => {
    if (location.pathname !== '/') {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(() => {
        navigate('/');
      });
    } else {
      window.Telegram.WebApp.BackButton.hide();
      window.Telegram.WebApp.BackButton.offClick();
    }
  }, [location, navigate]);

  if (isLoading) {
    return (
      <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography variant="h6" component="div" ml={2}>
          {t('loading')}
        </Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Typography variant="body1" color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <>
      <Container sx={{paddingBottom: '50px',}}>
        <Suspense fallback={<CircularProgress />}>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/level-up-boost" element={<LevelUpBoost />} />
            <Route path="/referrals" element={<ReferralsPage />} />
            <Route path="/tasks" element={<TaskPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/top-users" element={<TopUsersPage />} />
            <Route path="/referral-marketplace" element={<ReferralMarketplacePage />} />
            <Route path="/daily-combo-detail" element={<DailyComboDetail />} />
            <Route path="/jumpingpig" element={<JumpingPig />} />
            <Route path="/mapgrid" element={<MapGrid />} />
            <Route path="*" element={<MainPage />} />
          </Routes>
        </Suspense>
      </Container> 
      <Box sx={{ position: 'fixed', bottom: 0, width: '100vw', backgroundColor: '#fff', zIndex: 1 }}>
        <BottomNav />
      </Box>       
    </>
  );
}

export default function AppWrapper() {
  return (
    <UserProvider>
      <Router>
        <App />
      </Router>
    </UserProvider>
  );
}
