import React, { useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction, Badge, Box, Menu, MenuItem, ListItemIcon } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import GroupIcon from '@mui/icons-material/Group';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import StoreIcon from '@mui/icons-material/Store';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function BottomNav() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [taskCount, setTaskCount] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    const fetchActiveTasksCount = async () => {
      try {
        const response = await axios.post('/tasks/activeCount', {
          initData: window.Telegram.WebApp.initData,
        });
        setTaskCount(response.data.activeCount);
      } catch (error) {
        console.error('Error fetching active tasks count:', error);
      }
    };

    fetchActiveTasksCount();
  }, []);

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === 0) {
      navigate('/');
    }
    if (newValue === 1) {
      navigate('/level-up-boost');
    }
    if (newValue === 2) {
      setAnchorEl(event.currentTarget);
    }
    if (newValue === 3) {
      navigate('/tasks');
    }
    if (newValue === 4) {
      navigate('/top-users');
    }
  };

  const handleReferralOptionClick = (option) => {
    if (option === 'referrals') navigate('/referrals');
    if (option === 'marketplace') navigate('/referral-marketplace');
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <BottomNavigation
        value={value}
        onChange={handleNavigationChange}
        showLabels
        className="bottom-navigation"
      >
        <BottomNavigationAction label={t('home')} icon={<HomeIcon />} />
        <BottomNavigationAction label={t('boost')} icon={<RocketLaunchIcon />} />
        <BottomNavigationAction label={t('referralsmenu')} icon={<GroupIcon />} />
        <BottomNavigationAction
          label={t('tasks')}
          icon={
            <Badge badgeContent={taskCount} color="error">
              <AssignmentIcon />
            </Badge>
          }
        />
        <BottomNavigationAction label={t('topMenu')} icon={<LeaderboardIcon />} />
      </BottomNavigation>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => handleReferralOptionClick('referrals')}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          {t('referralsmenu')}
        </MenuItem>
        <MenuItem onClick={() => handleReferralOptionClick('marketplace')}>
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          {t('referralMarketplace')}
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default BottomNav;
