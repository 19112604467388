// frontend/src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const supportedLngs = ['en', 'ru','ro','tr','uk','it','fr','de','ar','hi','zh']; // Limbile suportate

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    supportedLngs: supportedLngs, // Specificăm limbile suportate
    nonExplicitSupportedLngs: false, // Nu încărcăm automat traduceri pentru variante regionale (de exemplu, en-US)
    load: 'languageOnly', // Încărcăm doar traducerile pentru limba selectată
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage', 'cookie'],
    },
  })
  .then(() => {
    // Adăugăm un event listener pentru a gestiona schimbarea limbii
    i18n.on('languageChanged', (lng) => {
      if (!supportedLngs.includes(lng)) {
        // Dacă limba nu este suportată, afișăm o eroare și revenim la limba implicită (engleză)
        //console.log('Limba selectată nu este suportată. Se va folosi limba engleză.');
        i18n.changeLanguage('en');
      }
    });
  });

export default i18n;
