// frontend/src/components/NotificationProvider.jsx

import React from 'react';
import { SnackbarProvider } from 'notistack';

function NotificationProvider({ children }) {
  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      {children}
    </SnackbarProvider>
  );
}

export default NotificationProvider;
